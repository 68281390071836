<template>
  <div class="d-flex">
    <b-media
      :tag="notification.targetURL ? 'a' : 'div'"
      :style="{ flexGrow: 1 }"
      @click="handleReadNotification()"
    >
      <template #aside>
        <b-avatar
          size="32"
          :src="notification.iconURL"
          variant="light-success"
        >
          <feather-icon v-if="!notification.iconURL" icon="CheckCircleIcon" />
        </b-avatar>
      </template>
      <small class="notification-text text-muted">{{ notificationDate }}</small>
      <p class="media-heading mb-0">
        <span class="font-weight-bolder text-dark">
          {{ notification.title }}
        </span>
      </p>
      <small class="notification-text text-muted">{{ notification.message.replace(/<\/?[^>]+>/ig, " ")  }}</small>
    </b-media>
    <div v-if="!notification.isRead" class="d-flex">
      <b-button
        variant="success"
        class="p-50 mx-2 align-self-center"
        :disabled="isDismissing"
        @click="dismissNotification"
      >
        <b-icon-circle-fill v-if="isDismissing" animation="throb" />
        <b-iconstack v-else>
          <b-icon-circle stacked />
          <b-icon-check stacked />
        </b-iconstack>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BMedia, BButton, BIconstack, BIconCheck, BIconCircle, BIconCircleFill,
} from 'bootstrap-vue';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'NotificationItem',
  components: {
    BAvatar, BMedia, BButton, BIconstack, BIconCheck, BIconCircle, BIconCircleFill,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDismissing: false,
    };
  },
  computed: {
    notificationDate() {
      return this.$moment(this.notification.updatedAt).format('dddd, D MMMM YYYY, h:mm');
    },
  },
  methods: {
    async dismissNotification() {
      this.isDismissing = true;
      try {
        await this.$store.$service[Service.BackendClient]
          .post('/markAsReadNotification', { key: this.notification.key });

        this.$emit('dismissed', this.notification);
      } catch {
        this.notifyError(this.$t('notifications.message.mark-as-read-error'));
      }
      this.isDismissing = false;
    },
    async handleReadNotification() {
      await this.dismissNotification();
      window.location.href = this.notification.targetURL;
    },
  },
};
</script>
