<template>
  <div class="d-flex align-items-center">
    <h6 class="font-weight-bolder mr-auto mb-0">
      {{ $t('notifications.enable-push-notifications') }}
    </h6>
    <b-form-checkbox
      v-model="isSubscribed"
      switch
      :disabled="isLoading"
      @change="handleSubscriptionChange"
    />
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_GETTERS } from '@/store/notifications/notifications-store-constants';

export default {
  name: 'PushNotificationsCheckbox',
  components: { BFormCheckbox },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isSubscribed: false,
      areNotificationsBlocked: false,
      isLoading: false,
    };
  },
  computed: {
    isSubscribedToPushNotifications() {
      return this.$store.getters[NOTIFICATIONS_GETTERS.isSubscribedToPushNotifications];
    },
  },
  watch: {
    isSubscribedToPushNotifications(isSubscribedToPushNotifications) {
      this.isSubscribed = isSubscribedToPushNotifications;
    },
  },
  mounted() {
    // console.log('this.isSubscribedToPushNotifications', this.isSubscribedToPushNotifications);
    this.isSubscribed = this.isSubscribedToPushNotifications;
  },
  methods: {
    async handleSubscriptionChange(subscribe) {
      this.isLoading = true;
      try {
        const status = await this.$store.dispatch(NOTIFICATIONS_ACTIONS.toggleNotifications, subscribe);
        this.notifySuccess(this.$t(`notifications.message.${status}`));
      } catch (error) {
        this.notifyError(this.$t(`notifications.message.${error.message}`));
        this.isSubscribed = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
